<template>
  <div>
    <div class="title">学习中心首页</div>
    <div class="tab">
      <div class="tab_item">
        <div class="tab_item_1">今天上课</div>
        <div class="tab_item_2">
          <span>{{ report.hour ? report.hour : "0" }}</span>
          <span>小时</span>
          <span>{{ report.min ? report.min : "00" }}</span>
          <span>分</span>
        </div>
      </div>
      <div class="tab_item">
        <div class="tab_item_1">累计学习课程</div>
        <div class="tab_item_2">
          <span>{{ report.curriculum || 0 }}</span
          >节
        </div>
      </div>
      <div class="tab_item">
        <div class="tab_item_1">签到天数</div>
        <div class="tab_item_2">
          <span>{{ report.sign || 0 }}</span
          >天
        </div>
      </div>
    </div>
    <div class="title1" style="margin-top: 27px">
      <span class="span_1">学习班级</span>
      <span class="span_2" @click="goclass">更多班级</span>
    </div>
    <div class="module">
      <img
        v-if="show"
        class="leftbtn"
        @click="zd"
        @mouseenter="enter"
        src="../../assets/zjt.png"
      />
      <img
        @mouseenter="enter"
        v-if="show"
        class="rigthbtn"
        @click="yd"
        src="../../assets/yjt.png"
      />
      <div class="box" @mouseenter="enter" @mouseleave="leave">
        <div class="courselist" ref="yd">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="item.flag == true ? 'items active' : 'items'"
            @click="cut(index, item)"
          >
            <div :class="item.flag == true ? 'title active' : 'title'">
              学习课程：{{ item.title }}
            </div>
            <div :class="item.flag == true ? 'time active' : 'time'">
              时间：{{ item.starttime }}～{{ item.endtime }}到期
            </div>
            <div
              style="
                display: flex;
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              "
            >
              <el-progress
                :show-text="false"
                style="width: 160px"
                :percentage="item.ksSpeed"
                :color="item.flag == true ? '#fe9549' : '#409eff'"
              ></el-progress>
              <span style="transform: translateY(-5px); margin-left: 30px">
                学习进度</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="titles">
          班级：{{ title || "暂无" }} &nbsp;&nbsp;&nbsp; 学习进度：{{
            detail.ksSpeed || "暂无"
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系老师
        </div>
        <div class="statisticslist">
          <div class="item">
            <div class="item_1">已学课时</div>
            <div class="item_2">
              {{ detail.ks || 0 }}<span> 课时</span>
              <span
                class="item_3"
                @click="dialogVisible = true"
                v-if="
                  row.class.audio_time == 1 ||
                  row.class.h5_time == 1 ||
                  row.class.video_times == 1 ||
                  row.class.practice_time == 1 ||
                  row.class.exam_time == 1
                "
                >学习详情</span
              >
            </div>
          </div>
          <div class="item">
            <div class="item_1">课程学习进度</div>
            <div class="item_2">{{ detail.ksSpeed || "暂无" }}</div>
          </div>
          <div class="item">
            <div class="item_1">累计签到次数</div>
            <div class="item_2">{{ detail.sign || 0 }} <span> 天</span></div>
          </div>
          <div class="item" v-if="detail.study == 1">
            <div class="item_1">视频时长</div>
            <div class="item_2">
              {{ detail.video_time || "00:00:00" }}
            </div>
          </div>
          <!-- <div class="item">
            <div class="item_1">课程答疑</div>
            <div class="item_2">10 <span> 次</span></div>
          </div> -->
        </div>

        <div class="titless">学习报告</div>

        <div class="reportlist">
          <div class="item">
            <img src="../../assets/report1.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                音频课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.audio"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report2.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                HTML5课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.knowledge"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report3.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                视频课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.video"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report4.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                章节练习
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.chapter"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report5.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                题库练习
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.Total"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report6.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                综合测试
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.questions"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="课程学习时长" :visible.sync="dialogVisible" width="585">
      <div class="reportlist1">
        <div class="item" v-if="row.class.audio_time == 1">
          <img src="../../assets/report1.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              音频课时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.total_audio_time ? row.total_audio_time : "00:00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.h5_time == 1">
          <img src="../../assets/report2.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              HTML5时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.total_h5_time ? row.total_h5_time : "00:00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.video_times == 1">
          <img src="../../assets/report3.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              视频课时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.total_video_time ? row.total_video_time : "00:00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.practice_time == 1">
          <img src="../../assets/report4.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              答疑练习时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{
                row.total_practice_time ? row.total_practice_time : "00:00:00"
              }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.exam_time == 1">
          <img src="../../assets/report6.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              综合测试时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.total_exam_time ? row.total_exam_time : "00:00:00" }}
            </div>
          </div>
        </div>
      </div>
      <div class="xian" v-if="row.class.actual_time == 1"></div>
      <div
        style="display: flex; margin: 20px 0 0 20px"
        v-if="row.class.actual_time == 1"
      >
        <img
          style="margin-right: 16px; width: 36px; height: 36px"
          src="../../assets/learn.jpg"
          alt=""
        />
        <div>
          <div
            style="
              margin-bottom: 8px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            "
          >
            学习时长( 音频 | HTML5 | 视频 )
          </div>
          <div style="width: 200px; color: #ff5f00; font-size: 16px">
            {{ row.total_actual_time ? row.total_actual_time : "00:00" }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      report: {},
      detail: {},
      distance: 0,
      avtive: 0,
      index: 0,
      tiem: 0,
      ks: 0,
      time: 0,
      time: null,
      flag: true,
      list: [],
      title: "",
      page: 1,
      row: {},
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  methods: {
    ...mapActions([
      "getMinePace",
      "getPresentation",
      "getMinePaceDetail",
      "fiveCount",
    ]),
    //向右移动
    yd() {
      if (this.list.length < 3) return;
      if (this.index > -1 && this.index < this.list.length - 3) {
        if (this.flag == true) {
          this.index += 1;
          this.flag = false;
          this.distance += 305;
          let num = this.distance - 305;

          this.time = setInterval(() => {
            num += 10;
            if (num > this.distance) {
              clearInterval(this.time);
              this.flag = true;
              this.$refs.yd.style.right = `${this.distance}px`;
            } else {
              this.$refs.yd.style.right = `${num}px`;
            }
          }, 10);
        }
      }
    },
    //向左移动
    zd() {
      if (this.list.length < 3) return;
      if (this.index > 0 && this.index <= this.list.length) {
        if (this.flag == true) {
          this.index -= 1;

          this.flag = false;
          this.distance -= 305;
          let num = this.distance + 305;
          this.time = setInterval(() => {
            num -= 10;
            if (num < this.distance) {
              clearInterval(this.time);
              this.flag = true;
              this.$refs.yd.style.right = `${this.distance}px`;
            } else {
              this.$refs.yd.style.right = `${num}px`;
            }
          }, 10);
        }
      }
    },
    //更多班级
    goclass() {
      this.$router.push("/Layout/mynterpretation");
    },
    // 移入 移出事件
    enter() {
      this.show = true;
    },
    leave() {
      this.show = false;
    },
    cut(index, item) {
      this.list.forEach((e, i) => {
        e.flag = i == index ? true : false;
      });
      this.title = item.title;
      this.fiveCount({
        username: this.userInfo.username,
        id: item.id,
        user_id: this.userInfo.id,
      }).then((res) => {
        if (res.code == 200) {
          this.row = res.data;
        }
      });
      this.getMinePaceDetail({
        username: this.userInfo.username,
        id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          res.data.audio = +res.data.audio.replace("%", "");
          res.data.knowledge = +res.data.knowledge.replace("%", "");
          res.data.video = +res.data.video.replace("%", "");
          res.data.chapter = +res.data.chapter.replace("%", "");
          res.data.Total = +res.data.Total.replace("%", "");
          res.data.questions = +res.data.questions.replace("%", "");
          this.detail = res.data;
        }
      });
    },
    getlist() {
      this.getMinePace({
        username: this.userInfo.username,
        page: this.page,
      }).then((res) => {
        if (res.code == 200) {
          if (res.msg != "暂无数据!") {
            res.data.forEach((e, i) => {
              e.starttime = e.starttime.substring(0, 10);
              e.endtime = e.endtime.substring(0, 10);
              e.ksSpeed = +e.ksSpeed.replace("%", "");
            });
            this.list = this.list.concat(res.data);
            if (res.data.length == 15) {
              this.page = this.page + 1;
              this.getlist();
            }
          }
        }
      });
    },
  },
  mounted() {
    this.getMinePace({
      username: this.userInfo.username,
      page: this.page,
    }).then((res) => {
      if (res.code == 200) {
        if (res.msg != "暂无数据!") {
          res.data.forEach((e, i) => {
            e.starttime = e.starttime.substring(0, 10);
            e.endtime = e.endtime.substring(0, 10);
            e.ksSpeed = +e.ksSpeed.replace("%", "");
            if (i == 0) {
              e.flag = true;
            } else {
              e.flag = false;
            }
          });
          this.title = res.data[0].title;
          this.fiveCount({
            username: this.userInfo.username,
            id: res.data[0].id,
            user_id: this.userInfo.id,
          }).then((res) => {
            if (res.code == 200) {
              this.row = res.data;
            }
          });
          this.list = res.data;
          if (res.data.length == 15) {
            this.page = this.page + 1;
            this.getlist();
          }
          this.getMinePaceDetail({
            username: this.userInfo.username,
            id: res.data[0].id,
          }).then((res) => {
            if (res.code == 200) {
              res.data.audio = +res.data.audio.replace("%", "");
              res.data.knowledge = +res.data.knowledge.replace("%", "");
              res.data.video = +res.data.video.replace("%", "");
              res.data.chapter = +res.data.chapter.replace("%", "");
              res.data.Total = +res.data.Total.replace("%", "");
              res.data.questions = +res.data.questions.replace("%", "");
              this.detail = res.data;
            }
          });
        }
      }
    });
    this.getPresentation({
      username: this.userInfo.username,
      type: 1,
    }).then((res) => {
      if (res.code == 200) {
        this.report = res.data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.reportlist1 {
  margin-top: 43px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .item {
    display: flex;
    width: 30%;
    margin-left: 20px;
    margin-bottom: 64px;
    img {
      margin-right: 16px;
      width: 36px;
      height: 36px;
    }
  }
}
.xian {
  width: 100%;
  height: 1px;
  border: 1px solid #cfcfcf;
}
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.leftbtn {
  position: absolute;
  cursor: pointer;
  left: 0px;
  top: 50px;
  z-index: 999;
}
.rigthbtn {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 50px;
  z-index: 999;
}
.tab {
  margin-top: 14px;
  height: 170px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .tab_item_1 {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .tab_item_2 {
    margin-top: 9px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    span {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
  }
}
.module {
  position: relative;
  margin-top: 22px;
  background: #ffffff;
  .box {
    display: -webkit-box;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .courselist {
    position: relative;
    display: flex;
    box-sizing: border-box;

    .active {
      color: #ffffff !important;
      background: #137cfb !important;
      &::before {
        display: block;
        content: "";
        background: #137cfb;
      }
    }
    .items {
      padding: 24px;
      box-sizing: border-box;
      position: relative;
      width: 298px;
      height: 133px;
      background: #f8f8f8;
      margin-right: 7px;
      color: #000;
      &:hover {
        border: 1px solid #23b8ff;
        padding: 23px !important;
      }
      .title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
      }
      .time {
        margin-top: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
      }
    }
  }
  .content {
    background-color: #fff;
    padding: 27px;
    box-sizing: border-box;
    .titles {
      height: 46px;
      border-bottom: 1px solid #e1e1e1;
    }
    .statisticslist {
      height: 163px;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        text-align: center;
      }
      .item_1 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b5b5b6;
      }
      .item_2 {
        margin-top: 8px;
        font-size: 36px;
        font-family: ArialMT;
        color: #ff5f00;
        span {
          font-size: 14px;
          font-family: HiraginoSansGB-W3, HiraginoSansGB;
          font-weight: normal;
          color: #000000;
        }
      }
      .item_3 {
        cursor: pointer;
        transform: translateY(-5px);
        margin-left: 10px;
        display: inline-block;
        width: 68px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        background: #e8f0fa;
        border: 2px solid #ccd6e2;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
    }
    .titless {
      margin-top: 34px;
      font-size: 21px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .reportlist {
      margin-top: 43px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .item {
        display: flex;
        width: 30%;
        margin-bottom: 64px;
        align-items: center;
        img {
          margin-right: 16px;
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
.title1 {
  display: flex;
  justify-content: space-between;
  .span_1 {
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .span_2 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
</style>
